import { AppRoutes, TabRoutes } from '@vatom/wallet-routes'
import { z } from 'zod'

const InventorySchema = z.object({
  fqdn_whitelist: z.array(z.string()).optional().default([]),
  tv_blacklist: z.array(z.string()).optional().default([]),
  object_definition_blacklist: z.array(z.string()).optional().default([]),
  splashContent: z.boolean().optional().default(true),
  pinned: z.array(z.string()).optional().optional().default([])
})

const nativeBaseColorSchema = z.union([
  z.string(),
  z.object({
    linearGradient: z
      .object({
        colors: z.array(z.string()),
        start: z.array(z.number()).optional(),
        end: z.array(z.number()).optional(),
        locations: z.array(z.number()).optional()
      })
      .optional()
  })
])

const ARSchema = z.object({
  visibleRadius: z.number().default(500),
  maxItems: z.number().default(5),
  resizeObjects: z.boolean().default(true),
  desiredRadius: z.number().default(1),
  scalingStrategy: z.string().default('new'),
  distanceFromCamera: z.number().default(5),
  objectBaseHeight: z.number().default(2),
  minDistance: z.number().default(5),
  additionalRandomDistance: z.number().default(10),
  ambientLightColor: z.string().default('0x404040')
})

const VersionSchema = z.object({
  recommended: z.string(),
  minimum: z.string(),
  link: z.string()
})

const ApiSchema = z.object({
  vatom: z.string().optional().default('https://api.vi.vatom.network'),
  vatoms: z.string().optional().default('https://vatoms.api.vatominc.com'),
  userVatom: z.string().optional().default('https://api.vi.vatom.network'),
  oidc: z.string().optional().default('https://id.vatom.com'),
  geo: z.string().optional().default('https://maps.googleapis.com'),
  billing: z.string().optional().default('https://billing.api.vatominc.com'),
  businesses: z.string().optional().default('https://businesses.api.vatominc.com'),
  studio: z.string().optional().default('https://studio.api.vatominc.com'),
  points: z.string().optional().default('https://points.api.vatominc.com'),
  events: z.string().optional().default('https://events.api.vatominc.com'),
  loyalty: z.string().optional().default('https://loyalty.api.vatominc.com'),
  network: z.string().optional().default('https://network.api.vatominc.com'),
  users: z.string().optional().default('https://users.vatom.com/')
})

const MapsSchema = z.object({
  pickup: z
    .object({
      enabled: z.boolean().default(true),
      redirect: z.string().default('https://maps.google.com')
    })
    .optional()
    .default({ enabled: true, redirect: 'https://maps.google.com' }),
  fqdn_whitelist: z.array(z.string()).default([]),
  max_items: z.number().default(20)
})

const FirebaseSchema = z.object({
  apiKey: z.string().default('AIzaSyBVY_EQCistyQdbJgEaMeR5kZadFLSt61o'),
  authDomain: z.string().default('ydangle-high-fidelity-test-2.firebaseapp.com'),
  databaseURL: z.string().default('https://ydangle-high-fidelity-test-2.firebaseio.com'),
  projectId: z.string().default('ydangle-high-fidelity-test-2'),
  storageBucket: z.string().default('ydangle-high-fidelity-test-2.appspot.com'),
  messagingSenderId: z.string().default('190616353628'),
  appId: z.string().default('1:190616353628:ios:4cb17f49c64e79342a1f99'),
  measurementId: z.string().default('G-WJ1NRS9JKW')
})

const chains = ['sol', 'eth', 'cspr'] as const

const IconSchema = z.object({
  icon: z.string().optional(),
  style: z.any().optional()
})

const ActionSchema = z.object({
  text: z.string().optional(),
  leftIcon: z.string().optional(),
  style: z.any().optional(),
  action: z.string().optional()
})

const ActionSheetSchema = z.object({
  customActions: z.array(ActionSchema).optional().default([]),
  generalInfo: z
    .array(z.string())
    .optional()
    .transform(info => (info ? info.map(v => v.replace(/\s/g, '').toLowerCase()) : []))
})

export const NftDetalSchema = z
  .object({
    hideCloseButton: z.boolean().optional().default(false),
    customActionBtn: IconSchema.optional(),
    hideTokenActions: z.boolean().optional().default(false),
    actionsheet: ActionSheetSchema.optional()
  })
  .optional()

export const RoomEschema = z
  .object({
    hideBackButton: z.boolean().optional().default(false)
  })
  .optional()

export const CommunitiesRoomSchema = RoomEschema

export const WalletSchema = z
  .object({
    scanner: z.boolean().default(true),
    emptyStateImage: z.string().optional(),
    emptyStateTitle: z.string().optional().default('Welcome to your Wallet!'),
    emptyStateMessage: z
      .string()
      .optional()
      .default('Looks like your wallet is empty. Add your wallet address to show your NFTs'),
    inventoryFilter: z.string().optional(),
    showInventory: z.boolean().default(true)
  })
  .optional()

export const MapEschema = z
  .object({
    mapStyle: z.any().optional(),
    // hide AR pickup in the wallet
    disableArPickup: z.boolean().default(false)
  })
  .optional()

export const LoginSchema = z
  .object({
    showUsername: z.boolean().default(true),
    showUserQRCode: z.boolean().default(true),
    showUserRelations: z.boolean().default(true),
    showEditProfile: z.boolean().default(true),
    // this prop allows to hide the mannage account button in the profile screen
    showManageAccountButton: z.boolean().default(true),
    // this prop allows to hide the business profile option in the profile screen
    showBusinessProfile: z.boolean().default(true),
    profileHeaderBackgroundColor: nativeBaseColorSchema.optional()
  })
  .optional()

export const screensConfigSchema = z.object({
  [AppRoutes.Room]: RoomEschema.optional().default({}),
  [AppRoutes.CommunitiesRoom]: CommunitiesRoomSchema.optional().default({}),
  [AppRoutes.NFTDetail]: NftDetalSchema.optional().default({}),
  [TabRoutes.Wallet]: WalletSchema.optional().default({}),
  [TabRoutes.Map]: MapEschema.default({}),
  [AppRoutes.profileUser]: LoginSchema.optional().default({})
})

export const FeaturesSchema = z.object({
  // login: LoginSchema,
  // faces: FacesSchema,
  // header: HeaderFooterSchema,
  // footer: HeaderFooterSchema,
  inventory: InventorySchema.default(InventorySchema.parse({})),
  // vatom: VatomSchema,
  // loginPopup: LoginPopupSchema,
  // claim: ClaimSchema,
  // incomingOverlay: IncomingOverlaySchema,
  // fullScreenScroll: z.boolean(),
  // campaignHeader: z.boolean(),
  ar: ARSchema.default(ARSchema.parse({})),
  maps: MapsSchema.default(MapsSchema.parse({})),
  allowedChains: z.enum(chains).array().default(['sol', 'eth', 'cspr']),
  favicon: z.string().optional(),

  // TODO: REMOVE THIS
  customActionBtn: IconSchema.optional(),
  customActions: z.array(ActionSchema).optional(),
  hideCloseButtonOnNft: z.boolean().default(false),
  inventoryFilter: z.string().optional(),
  // ==================

  visibleTabs: z.array(z.string()).optional(),
  // set Lenguage for the app
  language: z.enum(['en', 'es']).optional().default('en'),
  // set the system theme for the wallet
  systemThemeOverride: z.enum(['light', 'dark']).optional(),
  // hide the drawer in the wallet
  hideDrawer: z.boolean().default(false),
  // hide the navigation in the wallet
  hideNavigation: z.boolean().default(false),
  // disable notifications in the wallet
  disableNewTokenToast: z.boolean().default(false),
  // hide logo app in the wallet, looks like the same as hideDrawer
  showProductSelector: z.boolean().default(false),
  // config for each screen
  screensConfig: screensConfigSchema.optional().default(screensConfigSchema.parse({}))
})

export const AuthSchema = z.object({
  clientId: z.string().default('94JHkdj8jF83jfFF2LI8Q4'),
  useProxy: z.boolean().default(false),
  discoveryUrl: z.string().default('https://id.vatom.com'),
  redirectUri: z.string().default('com.vatom://auth'),
  scopes: z.array(z.string()).default(['openid', 'profile', 'email', 'offline_access'])
})

export const AppConfigurationSchema = z
  .object({
    appID: z.string().default('d9fd1482-3625-4746-854f-726b9032d4ff'),
    appTitle: z.string().default('Vatom Viewer'),
    businessId: z.string().default('system'),
    subBusinesses: z
      .array(z.string())
      .nullable()
      .transform(value => value ?? [])
      .optional()
      .default([]),
    viewerId: z.string().default('94JHkdj8jF83jfFF2LI8Q4'),
    oauthServer: z.string().default('https://id.vatom.com'),
    oidcAuthority: z.string().default('https://id.vatom.com'),
    oidcClientId: z.string().default('94JHkdj8jF83jfFF2LI8Q4'),
    mapBoxKey: z
      .string()
      .optional()
      .default(
        'pk.eyJ1IjoiYXNoaXNoYXR2YXRvbWluYyIsImEiOiJjazlpdXR3M2YwMGNjM2xwcGE1ZDhqMm5jIn0.xBI8uwwTY1H-NOAgtTBvOg'
      ),
    studioServer: z.string().optional().default('https://studio.vatom.com'),
    googleMapsKey: z.string().optional().default('AIzaSyCZsQ5Q3-ZYbcilFxIvjEE9x1S-9o-Fyak'),
    googleContactsClientID: z
      .string()
      .optional()
      .default('676578017431-8c4hnqbltbci6nv0j1moijhsl5s4u784.apps.googleusercontent.com'),
    analyticsTrackingID: z
      .string()
      .optional()
      .default('676578017431-8c4hnqbltbci6nv0j1moijhsl5s4u784.apps.googleusercontent.com'),
    geoLocationApi: z
      .string()
      .optional()
      .default(
        'https://api.ipstack.com/check?access_key=f6c1392b737ae773c32e839ccd03e87f&fields=country_code,latitude,longitude'
      ),
    algoliaApiKey: z.string().optional().default('be2a4102cb0d9d9d6e3cadd46dbf24dc'),
    algoliaAppId: z.string().default('1WB4E7CA90'),
    firebase: FirebaseSchema.default(FirebaseSchema.parse({})),
    websocketServer: z.string().optional().default('wss://ws.vatominc.net'),
    features: FeaturesSchema.optional().default(FeaturesSchema.parse({})),
    firebaseVapidKey: z
      .string()
      .default(
        'BKVxCEXAz6fv-PP0B6ThsF56acxr4bJ14ku-LDZR-vb2LH4ejeeAvCaxGvq2YPpuVX6JWw80_6EE6XeLPwTiMGs'
      ),
    appKey8thWall: z
      .string()
      .optional()
      .default('q6f9KSydWOgg0tZAqX7lKHT1kXBa8GNvgQqwxk6UGTadk8Io1pqPBUMt9HTlTLJx4d49lR'),
    // authentication: AuthSchema.default(AuthSchema.parse({})),
    server: z.string().optional().default('https://api.vi.vatom.network'),
    api: ApiSchema.optional().default(ApiSchema.parse({})),

    browserCompatbilityRegexes: z.array(z.string()).optional(),
    versions: z
      .object({
        ios: VersionSchema,
        android: VersionSchema
      })
      .optional(),

    termsOfUseURL: z.string().optional().default('https://www.vatom.com/terms-of-service/'),

    privacyPolicyURL: z.string().optional().default('http://www.vatom.com/privacy-policy'),
    supportURL: z.string().optional().default('https://support.vatom.com/hc/en-us/requests/new'),

    // setting this as empty array "[]" would show the 3 non optional field (name, created, updated)
    // if this is null or undefined would show "All"
    // TODO: REMOVE THIS
    showGeneralInfo: z
      .array(z.string())
      .optional()
      .transform(info => (info ? info.map(v => v.replace(/\s/g, '').toLowerCase()) : null)),

    showProductSelector: z.boolean().default(false),
    showUsername: z.boolean().default(true),
    showUserQRCode: z.boolean().default(true),
    showUserRelations: z.boolean().default(true),
    showEditProfile: z.boolean().default(true),
    showSettingsButton: z.boolean().default(true),
    showBlockchainWalletButton: z.boolean().default(true),

    // this prop allows to hide the mannage account button in the profile screen
    showManageAccountButton: z.boolean().default(true),
    // this prop allows to hide the business profile option in the profile screen
    showBusinessProfile: z.boolean().default(true),

    profileHeaderBackgroundColor: nativeBaseColorSchema.optional(),
    experienceSDKApiWhiteList: z
      .array(
        z.object({
          url: z.string(),
          method: z.string()
        })
      )
      .optional()
      .default([
        {
          url: 'https://id.vatom.com/me',
          method: 'GET'
        }
      ])
    //====================
  })
  .transform(values => ({
    ...values,
    get isBusinessLocked() {
      return values.businessId !== 'system'
    },
    authentication: AuthSchema.parse({
      clientId: values.oidcClientId,
      discoveryUrl: values.oidcAuthority,
      redirectUri: 'com.vatom://auth',
      scopes: ['openid', 'profile', 'email', 'offline_access']
    })
  }))

export type AppConfig = z.infer<typeof AppConfigurationSchema>
export type AppConfigInput = z.input<typeof AppConfigurationSchema>
