import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import type { BVatomTokenType } from '@vatom/BVatom/plugin'
import type {
  BusinessCommunities,
  BusinessSnapshot,
  CompositeRegion,
  TokenType
} from '@vatom/sdk/core'
import type { BusinessData } from '@vatom/sdk/react'
import {
  getConfig,
  useAppConfig,
  useBusiness,
  useBusinesses,
  useBusinessesUser,
  useCommunities,
  useConfig,
  useFilterBlackList,
  useInventoryDeferred
} from '@vatom/sdk/react'
import { useColorMode } from 'native-base'

import { findKeyInParams } from '../navigators'

import { useBusinessThemeConfig } from './useBusinessTheme'

export const isVatom = (token: TokenType): token is BVatomTokenType => token.type === 'vatom'

export type BusinessWithCommunities = BusinessSnapshot & { communities: BusinessCommunities[] }

// Will show businesses defined in the config even if the user doesn't own tokens from them
// const useBusinessItems = () => {
//   const config = useConfig()
//   const { data: businessIds } = useBusinessesUser()
//   const ids = [...config.subBusinesses, ...(businessIds ?? [])]
//   const { data: businesses } = useBusinesses(ids)
//   return businesses ?? []
// }
const useBusinessItems = () => {
  const { data: businessIds } = useBusinessesUser()
  const { data: businesses, ...rest } = useBusinesses(businessIds ?? [])
  return {
    data: businesses ?? [],
    ...rest
  }
}

type InventoryByBusiness = {
  defer?: boolean
  businessId?: string
}
type InventoryByBusinessResult = {
  region: CompositeRegion | null
  tokens: CompositeRegion['walletTokens']
  isLoading: boolean
}
export const useInventoryByBusiness = ({
  defer
}: InventoryByBusiness = {}): InventoryByBusinessResult => {
  const inventory = useInventoryDeferred({ defer })
  const config = useConfig()
  const filterByBlackList = useFilterBlackList()
  const { business, businessIdentifier, isLoading } = useBusinessSelector()

  useEffect(() => {
    const fetchPageBusiness = async () => {
      await inventory?.fetchPage({
        businessId: business?.id ?? ''
      })
    }
    business?.id && fetchPageBusiness()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business])

  if (!businessIdentifier) {
    // No selected Business so return all tokens
    return {
      region: inventory?.bVatomRegion ?? null,
      tokens: inventory?.walletTokens || [],
      isLoading: inventory?.isLoading ?? true
    }
  }

  const businessId = business?.id ?? businessIdentifier
  const businessTokens: TokenType[] = businessId
    ? inventory?.filteredTokens.filter(t => {
        const isVatom = t.type === 'vatom' || t.type === 'vatom-new'

        if (!isVatom) {
          return false
        }

        const isSameBusinessId = t.studioInfo?.businessId === businessId
        if (isSameBusinessId) {
          return true
        }

        const isJoyAppHack =
          t.type === 'vatom' && (businessId === 'Cox1qh6ggb' || businessId === 'QkxH4IbOIl')

        if (isJoyAppHack) {
          return (t as BVatomTokenType).payload['vAtom::vAtomType'].publisher_fqdn === 'mx.joyapp'
        }

        const isCustomDomainRoot =
          config.isBusinessLocked && config.businessId === businessIdentifier

        if (isCustomDomainRoot && t.studioInfo?.businessId) {
          return config.subBusinesses.includes(t.studioInfo.businessId)
        }

        return false
      }) ?? []
    : inventory?.sortedTokens ?? []
  const tokens = businessTokens.filter(t => {
    const vatom = isVatom(t) ? t : false
    if (!vatom) return true
    return vatom.properties.parent_id === '.'
  })

  const tokensFiltered = filterByBlackList(tokens)
  // Move pinned objects to the beggining
  const pinnedTokenIds = business?.pageConfig?.features?.vatom?.pinned ?? []
  // we should respect the order of the pinned tokens and put those first.
  const pinnedTokens = pinnedTokenIds
    .map(id => {
      const token = tokensFiltered.find(t => t.studioInfo?.objectDefinitionId === id)
      return token
    })
    .filter(t => t) as TokenType[]

  const finalTokens = pinnedTokens.concat(tokensFiltered.filter(t => !pinnedTokens.includes(t)))

  return {
    region: inventory?.bVatomRegion ?? null,
    tokens: finalTokens,
    isLoading: inventory?.isLoading || isLoading
  }
}

type BusinessSelectorContextProps = {
  business: BusinessData | undefined
  businessIdentifier: string | undefined
  communities: BusinessCommunities[] | undefined
  communityId?: string | undefined
  isLoading: boolean
}
const BusinessSelectorContext = createContext({
  business: undefined,
  businessIdentifier: undefined,
  communities: undefined,
  communityId: undefined,
  isLoading: false
} as BusinessSelectorContextProps)

type BusinessSelectorProviderProps = PropsWithChildren<{
  businessIdentifier?: string
}>
const BusinessSelectorProvider = ({
  children,
  businessIdentifier: parentBusinessIdentifier
}: BusinessSelectorProviderProps) => {
  const config = useAppConfig()
  const businessIdentifier = config.data?.isBusinessLocked
    ? parentBusinessIdentifier ?? config.data?.businessId
    : parentBusinessIdentifier

  const { data: business, isLoading: isLoadingBusiness } = useBusiness({
    business: businessIdentifier,
    businessId: businessIdentifier,
    join: true
  })
  const communities = useCommunities(business?.id ?? '')

  const isLoading = config.isLoading || Boolean(businessIdentifier && isLoadingBusiness)

  const businessTheme = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })
  // COLOR MODE
  const { setColorMode } = useColorMode()
  const setThemeMode = useCallback(() => {
    if (isLoading && businessIdentifier) {
      // is loading stay the same until change
      return
    }
    // if (!isLoading && !businessIdentifier) {
    //   setColorMode(defaultColorMode)
    //   setColorStatusBarWebkit(businessTheme.pageTheme.background)
    //   return
    // }

    const colorMode = businessTheme.isDark ? 'dark' : 'light'
    setColorMode(colorMode)
    const headerStatusBarColor = business?.pageConfig?.features?.header?.brandedBackground
      ? businessTheme?.brandTheme?.primary
      : businessTheme?.brandTheme?.background
    setColorStatusBarWebkit(headerStatusBarColor ?? businessTheme.pageTheme.background)
    // if (!isLoading && businessIdentifier) {
    //   return
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessIdentifier, businessTheme, isLoading, setColorMode])

  useEffect(() => {
    setThemeMode()
  }, [setThemeMode])

  return (
    <BusinessSelectorContext.Provider
      value={{
        businessIdentifier,
        business,
        communities: communities.data,
        isLoading: isLoading
      }}
    >
      {children}
    </BusinessSelectorContext.Provider>
  )
}

const setColorStatusBarWebkit = (color: string) => {
  if (Platform.OS === 'web') {
    const metaTag = document.querySelector('meta[name="theme-color"]')
    metaTag?.setAttribute('content', color)
  }
}

const withBusinessSelector =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const businessKey = 'business'
    const config = getConfig()
    const businessIdFromConfig = config.isBusinessLocked ? config.businessId : undefined
    const businessIdentifier =
      businessIdFromConfig && config.subBusinesses?.length === 0
        ? businessIdFromConfig
        : // @ts-ignore
          findKeyInParams(props?.route, businessKey) || undefined

    return (
      <BusinessSelectorProvider businessIdentifier={businessIdentifier}>
        <Component {...props} />
      </BusinessSelectorProvider>
    )
  }

const useBusinessSelector = () => {
  const ctx = useContext(BusinessSelectorContext) as BusinessSelectorContextProps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoed = useMemo(() => ctx, [ctx.business?.id, ctx.businessIdentifier])
  return memoed
}

export { BusinessSelectorProvider, useBusinessItems, useBusinessSelector, withBusinessSelector }
