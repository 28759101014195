import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  allSettled,
  dmQueryKeys,
  isFulfilled,
  isRejected,
  useJoinMutation,
  useUser
} from '@vatom/sdk/react'

import { useSDK } from '../../../SDKProvider'

import {
  fetchMatrixPushers,
  fetchMatrixSync,
  fetchMatrixSyncNew,
  fetchMatrixUser,
  getMatrixUserDisplayName,
  setMatrixUserDisplayName
} from './api'
import type {
  MatrixData,
  MatrixFullStateSyncOptions,
  MatrixPollStateSyncOptions,
  RoomInfo
} from './types'

export const matrixQueryKeys = {
  user: [{ scope: 'matrix-user' }] as const,
  spaces: [{ scope: 'matrix-spaces' }] as const,
  fullStateSync: [{ scope: 'matrix-full-state-sync' }] as const,
  pollStateSync: [{ scope: 'matrix-poll-state-sync' }] as const,
  getMatrixUser: (accessToken: string) => [{ ...matrixQueryKeys.user[0], accessToken }] as const,
  getSpaces: (businessId: string, communityId?: string) =>
    [{ ...matrixQueryKeys.spaces[0], businessId, communityId }] as const,
  getMatrixFullStateSync: (matrixToken?: string) =>
    [{ ...matrixQueryKeys.fullStateSync[0], matrixToken }] as const,
  getMatrixPollStateSync: (matrixToken: string, nextBatch: string) =>
    [{ ...matrixQueryKeys.pollStateSync[0], matrixToken, nextBatch }] as const
}

export const usePushers = (accessToken?: string, userId?: string) => {
  const setPushersQuery = useQuery({
    queryKey: ['matrix-pushers'],
    queryFn: () => fetchMatrixPushers(accessToken, userId),
    enabled: !!accessToken
  })
  return setPushersQuery
}

export const useMatrixUserDisplayName = (accessToken?: string, userId?: string) => {
  const getDisplayName = useQuery({
    queryKey: ['matrix-user-get-display-name', userId],
    queryFn: () => getMatrixUserDisplayName(accessToken, userId),
    enabled: !!accessToken
  })

  const user = useUser()
  const isSameDisplayName = getDisplayName.data?.displayname === user?.name

  const setDisplayName = useQuery({
    queryKey: ['matrix-user-set-display-name', userId],
    queryFn: () => setMatrixUserDisplayName(user?.name, accessToken, userId),
    enabled: !!accessToken && !isSameDisplayName && getDisplayName.isSuccess
  })
  return setDisplayName
}

export const useMatrixUser = () => {
  const sdk = useSDK()
  const userDataQuery = useQuery({
    queryKey: matrixQueryKeys.getMatrixUser(
      sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken ?? ''
    ),
    queryFn: fetchMatrixUser,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!sdk.dataPool.sessionStore.vatomIncSessionToken?.accessToken
  })

  // const userDisplayName = useMatrixUserDisplayName(
  //   userDataQuery.data?.access_token,
  //   userDataQuery.data?.user_id
  // )
  // const pushersData = usePushers(userDataQuery.data?.access_token, userDataQuery.data?.user_id)

  return userDataQuery
}

export const useGetMatrixFullStateSync = <T = MatrixData>(
  options?: MatrixFullStateSyncOptions<T>
) => {
  const result = useQuery({
    queryKey: matrixQueryKeys.getMatrixFullStateSync(''),
    ...options,
    enabled: false
  })

  return result
}

// This should only be called once
export const useMatrixFullStateSync = <T = MatrixData>(options?: MatrixFullStateSyncOptions<T>) => {
  const matrixUser = useMatrixUser()

  const joinRoomMutation = useJoinMutation()

  const autoAcceptInvites = async (data: MatrixData) => {
    const invites = data.rooms?.invite ?? {}

    const inviteRoomIds = Object.keys(invites)
    if (inviteRoomIds.length === 0) {
      return []
    }
    const promises = inviteRoomIds.map(async roomId => {
      const roomInfo = invites[roomId]
      const inviteEvents = roomInfo?.invite_state?.events ?? []
      const roomIsDM = inviteEvents.find(event => event?.content?.is_direct === true)
      const otherInvites = inviteEvents.find(event => event.content?.membership === 'invite')

      const invitedBy = roomIsDM?.sender ?? otherInvites?.sender
      if (!invitedBy) {
        return Promise.reject('autoAcceptInvites: room is not DM or is not membership invite')
      }
      await joinRoomMutation.mutateAsync({ roomId, userId: invitedBy })
      if (joinRoomMutation.isSuccess) {
        return roomId
      }

      return Promise.reject(joinRoomMutation.error)
    })

    const acceptedInvitesPromiseResults = await allSettled(promises)
    const fulfilledValues = acceptedInvitesPromiseResults.filter(isFulfilled).map(p => p.value)
    const rejectedReasons = acceptedInvitesPromiseResults.filter(isRejected).map(p => p.reason)
    console.error(rejectedReasons)
    return fulfilledValues
  }

  const result = useQuery({
    queryKey: matrixQueryKeys.getMatrixFullStateSync(''),
    queryFn: async () => {
      return fetchMatrixSync({
        token: matrixUser.data?.access_token
      })
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
    enabled: !!matrixUser.data?.access_token && options?.enabled,
    onSuccess(data) {
      // console.log('LOG: > onSuccess > data:', data)
    }
  })

  const queryClient = useQueryClient()
  useMatrixPollSync({
    onSuccess: async response => {
      // console.log('LOG: > useMatrixFullStateSync > response:', response)
      const currentState = queryClient.getQueryState(matrixQueryKeys.getMatrixFullStateSync(''))
        ?.data as MatrixData | null
      // console.log('LOG: > useMatrixFullStateSync > currentState:', currentState?.rooms)

      if (currentState?.account_data) {
        currentState.account_data = {
          ...currentState.account_data,
          ...(response?.account_data ?? {})
        }
      }

      if (currentState?.rooms) {
        // NOTE: Auto Join Direct Messages Room
        const acceptedInvites = await autoAcceptInvites(currentState)
        if (Object.keys(currentState.rooms?.invite ?? {}).length === acceptedInvites.length) {
          if (currentState?.rooms?.invite) {
            acceptedInvites.forEach(roomId => {
              if (currentState?.rooms?.invite) {
                if (roomId in currentState.rooms.invite) {
                  delete currentState?.rooms.invite[roomId]
                }
              }
            })
          }
        }
      }

      if (response.presence) {
        if (currentState?.presence) {
          currentState.presence = response.presence
        }
      }
      // TODO: use notifications?
      // if (response.notifications) {
      //   if (currentState?.notifications) {
      //     currentState.notifications = response.notifications
      //   }
      // }

      if (response.rooms) {
        const acceptedInvites = await autoAcceptInvites(response)
        if (Object.keys(response.rooms?.invite ?? {}).length === acceptedInvites.length) {
          if (response?.rooms?.invite) {
            acceptedInvites.forEach(roomId => {
              if (response?.rooms?.invite) {
                if (roomId in response.rooms.invite) {
                  delete response?.rooms.invite[roomId]
                }
              }
            })
          }
        }

        if ('join' in response.rooms) {
          for (const roomKey in response.rooms.join) {
            if (!currentState) {
              break
            }

            if (!currentState?.rooms) {
              // For a new room
              currentState.rooms = response.rooms
              break
            }

            if (!currentState?.rooms?.join?.[roomKey]) {
              // For a new room
              currentState.rooms.join[roomKey] = response.rooms.join[roomKey]
              break
            }

            const room = response.rooms.join[roomKey] as RoomInfo
            console.log('LOG: > useMatrixFullStateSync > room:', room, roomKey)
            // queryClient.setQueryData(['matrix-room-data', roomKey], room)

            if (room.timeline.events.length > 0) {
              // update timeline events for room
              currentState.rooms.join[roomKey].timeline.events = [
                ...room.timeline.events,
                ...currentState.rooms.join[roomKey].timeline.events
              ]

              // Check for reactions in room events
              const reactionEvents = room.timeline.events.filter(
                event => event.type === 'm.reaction'
              )

              if (reactionEvents.length === 0) {
                const redacted = room.timeline.events.filter(
                  event =>
                    event.type === 'm.room.redaction' &&
                    event.content?.unsigned?.['m.relates_to']?.rel_type === 'm.annotation'
                )

                if (redacted.length > 0) {
                  // invalidate room message reaction
                  redacted.forEach(event => {
                    if (event.content?.unsigned?.['m.relates_to']?.event_id) {
                      const parentEventId = event.content['unsigned']['m.relates_to']['event_id']

                      queryClient.invalidateQueries({
                        queryKey: dmQueryKeys.getDmMessageReactions(roomKey, parentEventId)
                      })
                    }
                  })
                }
              }

              if (reactionEvents.length > 0) {
                // Invalidate queries for new message reactions from roomId
                reactionEvents.forEach(event => {
                  if (event?.content?.['m.relates_to']?.event_id) {
                    const eventId = event.content['m.relates_to']['event_id']
                    queryClient.invalidateQueries({
                      queryKey: dmQueryKeys.getDmMessageReactions(roomKey, eventId)
                    })
                  }
                })
              }

              // Check for messages in room events
              const messageEvents = room.timeline.events.filter(
                event => event.type === 'm.room.message'
              )

              // TODO: check for redaction ??
              if (messageEvents.length > 0) {
                // const userId = matrixUser.data?.user_id
                // console.log('LOG: > useMatrixFullStateSync > userId:', userId)
                // console.log('LOG: > useMatrixFullStateSync > messageEvents:', messageEvents)
                // Only for messages from other user ??
                queryClient.invalidateQueries({
                  queryKey: dmQueryKeys.getDmMessages(roomKey)
                })
              }
            }
            currentState.rooms.join[roomKey].timeline.prev_batch = room.timeline.prev_batch
            currentState.rooms.join[roomKey].unread_notifications = room.unread_notifications
            currentState.rooms.join[roomKey].account_data = room.account_data

            if (room.ephemeral.events.length > 0) {
              currentState.rooms.join[roomKey].ephemeral.events = room.ephemeral.events
            }
          }
        }

        if ('leave' in response.rooms) {
          for (const roomKey in response.rooms.leave) {
            if (!currentState) {
              break
            }

            if (!currentState?.rooms.leave) {
              currentState.rooms['leave'] = {}
            }

            if (!currentState?.rooms.leave[roomKey]) {
              // For a new room
              currentState.rooms.leave[roomKey] = response.rooms.leave[roomKey]
              break
            }

            const room = response.rooms.leave[roomKey] as RoomInfo

            currentState.rooms.leave[roomKey].timeline.events.push(...room.timeline.events)
          }
        }
      }

      if (response.next_batch !== currentState?.next_batch) {
        queryClient.setQueryData(matrixQueryKeys.getMatrixFullStateSync(''), {
          ...currentState,
          next_batch: response.next_batch
        })
      } else {
        queryClient.invalidateQueries(matrixQueryKeys.pollStateSync)
      }
    }
  })
  return result
}

export const useMatrixPollSync = <T = MatrixData>(options?: MatrixPollStateSyncOptions<T>) => {
  const matrixUser = useMatrixUser()

  const currentFullState = useQuery<MatrixData>(matrixQueryKeys.getMatrixFullStateSync(''), {
    enabled: false
  })

  const result = useQuery({
    queryKey: matrixQueryKeys.getMatrixPollStateSync('', currentFullState.data?.next_batch ?? ''),
    queryFn: async ctx =>
      await fetchMatrixSyncNew({
        ...ctx,
        queryKey: matrixQueryKeys.getMatrixPollStateSync(
          matrixUser.data?.access_token ?? '',
          currentFullState.data?.next_batch ?? ''
        )
      }),
    ...options,
    enabled:
      !!matrixUser.data?.access_token && !!currentFullState.data?.next_batch && options?.enabled,
    cacheTime: 0
  })
  return result
}
